
import { recruitService } from "@/api";
import { ElMessage } from "element-plus/lib/components";
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { sessionStorage } from "@/utils/util";
import Login from "@/components/pc/login.vue";
export default defineComponent({
  props: {},
  components: {
    Login,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const state = reactive<{
      detail: any;
      dialogVisible: boolean;
      isLoading: boolean;
    }>({
      detail: null,
      dialogVisible: false,
      isLoading: true,
    });

    onMounted(() => {
      getDetail({ postId: route.query.postId });
    });
    function handleClose(done: () => void) {
      loginSuccess();
      done();
    }
    function loginSuccess() {
      state.dialogVisible = false;
      getDetail({ postId: route.query.postId });
    }
    function toudi() {
      if (store.state.appStore.token) {
        if (sessionStorage.get("accountId")) {
          recruitService.user
            .sendPost({ postId: state.detail.postId })
            .then(() => {
              state.detail.isDelivered = true;
              ElMessage.success("投递成功！");
            })
            .catch((val) => {
              if (val.isHttpStatus) {
                console.log(val.statusText);
              } else {
                ElMessage.error(val.message || "错误");
              }
            });
        } else {
          router.push(`/editCv?postId=${state.detail.postId}`).catch((err) => {
            console.warn(err);
          });
        }
      } else {
        state.dialogVisible = true;
      }
    }
    function getDetail(obj: any) {
      state.isLoading = true;
      recruitService.post
        .postDetail(obj)
        .then((val) => {
          const detail = val.data;
          if (detail.postTagText) {
            detail["postTagTextArr"] = detail.postTagText.split(",");
          }
          state.detail = detail;
          state.detail.jobContent = state.detail.jobContent
            ? state.detail.jobContent.replace(/\r?\n/g, "<br/>")
            : "";
          state.isLoading = false;
        })
        .catch((val) => {
          state.isLoading = false;
          if (val.isHttpStatus) {
            console.log(val.statusText);
          } else {
            ElMessage.error(val.message || "错误");
          }
        });
    }
    return {
      ...toRefs(state),
      getDetail,
      toudi,
      handleClose,
      loginSuccess,
    };
  },
});
